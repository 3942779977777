*{
  font-family: 'Montserrat', sans-serif;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.datos-card-cont{
  width: 460px;
  max-width: 90%;
}
.datos-card{
  width: 100%;
  border-radius: 10px !important;
  padding: 10px 0;
  -webkit-box-shadow: 0px 0px 15px 5px rgba(87,87,87,0.5) !important; 
  box-shadow: 0px 0px 15px 5px rgba(87,87,87,0.5)  !important;

}
.titulo{
  text-align: center;
  
  font-size: 22px;
  margin:20px 5% 20px 5%;
}
.subTitulo{
  text-align: center;
  margin:auto 5% 20px 5%;
}

.datos-card .MuiFormControl-root{
  width: 86%;
  margin: 8px 7%;
}

.btt-container{

  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.fechaGeneroCont{
  display: grid;
  grid-template-columns:  60% 40%;
  grid-column-gap: 20px;
  width: calc(86% - 20px);
  margin: 4px 7%;
}

.fechaGeneroCont .MuiFormControl-root{
  width: 100%;
  margin: auto;
}



.telefonoCont{
  display: grid;
  grid-template-columns:  30% 70%;
  grid-column-gap: 20px;
  width: calc(86% - 20px);
  margin: 4px 7%;
}

.telefonoCont .MuiFormControl-root{
  width: 100%;
  margin: auto;
}
.acepto-cont{
  margin: 10px 7% auto 7%;
}
.acepto-cont .MuiCheckbox-root{
  /*margin-left: -15px !important;*/
}

.bold{
  font-weight: bold;
}
.diarcoClub{
  color: #E21119;
  font-weight: bold;
}

.treintaDias{
  text-align: center;
  margin: 20px;
}
.pointer{
  cursor: pointer;
}


.main-loader{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000003b;
  z-index: 99999;
  display: none;
}

.lds-ellipsis {
  display: inline-block;
  position: fixed;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #D50000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

.MuiDialog-container{
      margin-left: -15px !important;
}


@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


@media (max-width: 700px) {
  .MuiDialog-container{
    margin-left: auto !important;
  }

}
